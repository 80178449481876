import { useState, useEffect, createContext, useContext } from "react";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth";
import { auth, db } from "../configs/firebase";
import { get, ref, } from "firebase/database";
import { toast } from "react-toastify";

const AuthContext = createContext();

export const useAuthContext = () => useContext(AuthContext);

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password).then((res) => {
      setUser(res.user);
      console.log(user);
    });
  };

const signIn = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const uid = userCredential.user.uid;

        if (uid === "4LGDLvI1Xpa8gZku1kDLXVXPHkC2") {
            toast.success("Logged in successfully as admin");
            return;
        }

        const snapshot = await get(ref(db, 'Admins/' + uid));
        if (snapshot.exists() && snapshot.val().isAdmin) {
            toast.success("Logged in successfully as admin");
        } else {
            toast.error("Invalid admin credentials!");
            await signOut(auth);
        }
    } catch (error) {
        console.error("SignIn Error: ", error);
        toast.error("Login failed: " + error.message);
    }
};



  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return unsubscribe;
  }, []);

  const contextValue = {
    auth,
    user,
    createUser,
    signIn,
    logout,
  };

  // return <UserContext.Provider value={(testContext, createUser)}>{children}</UserContext.Provider>;
  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
