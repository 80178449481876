const NotificationBadge = ({ count }) => {

  if(count === 0) return null

  return (
    <div className="absolute top-2 right-2 transform translate-x-1/2 -translate-y-1/2
                    flex justify-center items-center 
                    w-6 h-6 text-xs font-bold text-[#ffffff] bg-[#ff0505]  border-2 rounded-full">
      {count < 100 ? count : "+99"}
    </div>
  );
};

export default NotificationBadge;