import React, { useState } from "react";
import { Chart } from "react-google-charts";
import DoraHomeIcon from "../assets/dora_home_btn.svg";
import { HiSearch, HiFolder, HiOfficeBuilding, HiOutlinePencilAlt } from "react-icons/hi";
import { IoLogOut } from "react-icons/io5";
import { FaMapMarked, FaDatabase  } from "react-icons/fa";
import {  BsFileEarmarkBarGraphFill, BsFillCheckSquareFill } from "react-icons/bs";
import {  AiOutlineArrowLeft } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../contexts/AuthContext";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { db } from "../configs/firebase";
import { onValue, ref, remove, set, push, query, orderByChild, equalTo, get } from "firebase/database";
import ReactPaginate from "react-paginate";
import Loading from "../components/Loading";
import Modal from "../components/Modal";
import NoResultsFound from "../components/NoResultsFound";
import TruncateText from "../components/TruncateText";
import DateSelector from "../components/DateSelector";
import { ScatterPlotChart, PredictiveAnalysisChart, RiverLevelChart, PrecipitationChart, SoilMoistureChart }from "../components/FloodCharts"
import { predictFloodLikelihood } from "../components/FloodPredictionModel";
import NotificationBadge from "../components/NotificationBadge";
import NotificationNotch from "../components/NotificationNotch";


const Analytics = () => {
  // State managers
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [tempData, setTempData] = useState([]);
  const [currentData, setCurrentData] = useState([]);
  const [current, setCurrent] = useState([]);
  const [filterDate, setFilterDate] = useState("");
  const [filter, setFilter] = useState('all');
  const [pieData, setPieData] = useState([]);
  const [precipitation, setPrecipitation] = useState(0);
  const [soilMoisture, setSoilMoisture] =useState(0);
  const [riverLevel, setRiverLevel] = useState(0);
  const [showPredictionModal, setShowPredictionModal] = useState(0);
  const [predictionResult, setPredictionResult] = useState(0);
  const [saveResult, setSaveResult] = useState(false);
  const [editPredictionResult, setEditPredictionResult] = useState(false);

  const [unReadTotal, setUnReadTotal] = useState(0)

  // Search query state and possible search query items
  const [searchQuery, setSearchQuery] = useState("");
  const searchQueryItems = ["disasterType", "address", "description", "fullName", "date"];


  useEffect(() => {
    if (!searchQuery) {
      setData(tempData);
      console.log("Setting to temp");
    }
  }, [searchQuery]);

  const handleResetSearch = () => {
    setSearchQuery("");
    setData(tempData);
  };

  // Instantiate useNavigate hook for page redirect
  const navigate = useNavigate();

  // Instantiate AuthContext for use
  const { testContext, logout } = useAuthContext();

  // Show Flood Charts
  const [showFloodCharts, setShowFloodCharts] = useState(false);

  const convertReportDate = (reportDateStr) => {
      const date = new Date(reportDateStr);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 because getMonth() is 0-indexed
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
  }

  // Query reports
  const getReports = () => {
      setIsLoading(true);
      const dbRef = ref(db, "/Reports");

      onValue(dbRef, (snapshot) => {
          if (snapshot.exists()) {
              const reports = snapshot.val();
              const reportsList = [];
              for (let id in reports) {
                  reportsList.push({ id, ...reports[id] });
              }

              const unreadCount = reportsList.reduce((acc, report) => {
                if (report.isRead === false) { // Assuming isRead is a boolean
                  return acc + 1;
                }
                return acc;
              }, 0); // Start the count at 0

              // Now unreadCount has the total number of unread reports
              setUnReadTotal(unreadCount);

              // Filtering logic
              let filteredReports = reportsList;

              switch(filter) {
                  case 'year':
                      const yearToFilter = filterDate.year
                      filteredReports = reportsList.filter(report => {
                          const reportYear = new Date(report.date).getFullYear();
                          return reportYear === yearToFilter;
                      });
                      break;

                  case 'yearMonth':
                      const yearToFilterYM = filterDate.year
                      const monthToFilter = filterDate.month
                      filteredReports = reportsList.filter(report => {
                          const reportDate = new Date(report.date);
                          return reportDate.getFullYear() === yearToFilterYM && reportDate.getMonth() + 1 === monthToFilter;
                      });
                      break;

                  case 'yearMonthDay':
                      const dateToFilter = `${filterDate.year}-${String(filterDate.month).padStart(2, '0')}-${String(filterDate.day).padStart(2, '0')}`;
                      filteredReports = reportsList.filter(report => {
                          const convertedReportDate = convertReportDate(report.date); 
                          return convertedReportDate === dateToFilter;
                      });
                      break;

                  case 'all':
                  default:
                      // No filtering
                      break;
              }

              // Count disaster types from the filtered list
              const disasterTypeCounts = filteredReports.reduce((accumulator, report) => {
                  const index = accumulator.findIndex(item => item.disasterType === report.disasterType);

                  if (index !== -1) {
                      accumulator[index].totalCount++;
                  } else {
                      accumulator.push({
                          disasterType: report.disasterType,
                          totalCount: 1
                      });
                  }

                  return accumulator;
              }, []);

              function transformToArrayOfArrays(inputArr) {
                  const transformedData = inputArr.map(obj => [obj.disasterType, obj.totalCount]);
                  
                  const result = [
                      ["Disaster Type", "Total Occurence"],
                      ...transformedData
                  ];
                  
                  return result;
              }

              setTempData(disasterTypeCounts);
              setPieData(transformToArrayOfArrays(disasterTypeCounts));
              setData(disasterTypeCounts);
              setIsLoading(false);

          } else {
              console.error("No data");
          }
      });
  };

  // Logout function
  const handleLogout = async () => {
    try {
      await logout();
      toast.success("Logged out successfully");
      navigate("/");
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleDateChange = (date, filterType) => {
    setFilter(filterType)
    if(!date.year && !date.month && !date.day){
      setFilterDate("");
    } else{
      setFilterDate(date);
    }
  };

  const handlePredictionModal = () => {
    setShowPredictionModal(!showPredictionModal);
    setPredictionResult(0)
  };

  const handlePredictionSubmit = () => {
    let likelihood = predictFloodLikelihood(+precipitation, +soilMoisture, +riverLevel);
    likelihood = likelihood.toFixed(2)
    setPredictionResult(likelihood);
    if(+precipitation !== 0 && +soilMoisture !== 0 && +riverLevel !== 0){
      setSaveResult(true)
    }
  }

  const handleSavePredictionData = () => {
    const dateToFilter = `${filterDate.year}-${String(filterDate.month).padStart(2, '0')}-${String(filterDate.day).padStart(2, '0')}`;
    const predictionData = {
      date: dateToFilter,
      precipitation: +precipitation,
      soilMoisture: +soilMoisture,
      riverLevel: +riverLevel,
      floodlikelihood: +predictionResult
    }

    // Create a query to check if the date already exists
    const dateQuery = query(ref(db, 'FloodAnalytics'), orderByChild('date'), equalTo(dateToFilter));
    get(dateQuery).then((snapshot) => {
      if (snapshot.exists()) {
        // The date already exists in the database, handle the error
        toast.error("Data on this date already exists. Please choose a different date.")
        // You can perform any error actions here, e.g., show a message to the user
      } else {
        // The date does not exist, proceed to save the new data
        const newPredictionRef = push(ref(db, 'FloodAnalytics'));
        set(newPredictionRef, predictionData)
          .then(() => {
            toast.success("Data saved successfully!")
            setSaveResult(false)
            setFilter('all')
            // You can perform any success actions here, e.g., show a message to the user
          })
          .catch((error) => {
            toast.error("Failed to save data: " +  error);
            // Handle any errors that occur
          });
      }
    }).catch((error) => {
      toast.error("Failed to check for existing date: ", error);
      // Handle any errors that occur during the check
    });
  }

  // Fetch reports at page load
  useEffect(() => {
    getReports();
    // const likelihood = predictFloodLikelihood(3.1, 12.8, 4);
    // console.log('Predicted flood likelihood:', likelihood);
  }, [filterDate, filter]);

  // Pagination states
  const [pageNumber, setPageNumber] = useState(0);
  const reportPerPage = 6;
  const pagesVisited = pageNumber * reportPerPage;
  const pageCount = Math.ceil(data.length / reportPerPage);

  const handlePageChange = ({ selected }) => {
    setPageNumber(selected);
  };

const pieOptions = {
  title: "Disaster Types",
  is3D: true
};

  return (
    <div className="bg-bg-color flex flex-col md:flex-row items-start overflow-auto h-screen justify-between md:justify-start">
      {/* Navigation */}
      <aside className="hidden md:flex flex-col justify-between h-screen">
        {/* Upper Part */}
        <div className="h-[80%] w-24 bg-safe-gray rounded-br-2xl">
          <div className="overflow-y-auto py-4 px-4">
            <ul>
              <li>
                <Link to="/">
                  <img src={DoraHomeIcon} alt="DORAv4 Home Button" />
                </Link>
              </li>
              <li className="pt-48">
                <Link to="/evacuation" className="text-secondary-gray transition hover:text-primary-green focus:text-secondary-green">
                  {/* <img src="src\assets\reports_icon.svg" alt="Reports Icon" className="mx-auto" /> */}
                  <HiOfficeBuilding className="h-12 w-12 mx-auto" />
                </Link>
              </li>
              <li className="mt-6 relative">
                <NotificationBadge count={unReadTotal} />
                <Link to="/reports" className="text-secondary-gray transition hover:text-primary-green focus:text-secondary-green">            
                  <HiFolder className="h-12 w-12 mx-auto" />
                </Link>
              </li>
              <li className="pt-6">
                <Link to="/map" className="text-secondary-gray transition hover:text-primary-green focus:text-secondary-green">
                  {/* <img src="src\assets\reports_icon.svg" alt="Reports Icon" className="mx-auto" /> */}
                  <FaMapMarked className="h-12 w-12 mx-auto" />
                </Link>
              </li>
              <li className="pt-6">
                <Link to="/analytics" className="text-primary-green transition active:text-secondary-green">
                  {/* <img src="src\assets\reports_icon.svg" alt="Reports Icon" className="mx-auto" /> */}
                  <FaDatabase className="h-12 w-12 mx-auto" />
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* Bottom Part */}
        <div className="h-[15%] w-24 bg-safe-gray rounded-tr-2xl">
          <a href="#" onClick={() => handleLogout()} className="text-secondary-gray transition hover:text-primary-green active:text-secondary-green">
            {/* <img src="src/assets/logout_btn.svg" alt="Logout Button" className="mx-auto mt-8" /> */}
            <IoLogOut className="h-12 w-12 mt-8 mx-auto" />
          </a>
        </div>
      </aside>
      {/* Main Content Area */}
      {/* Headings */}
      <div className="container py-6 px-4">
        <h1 className="text-primary-green text-4xl font-medium mb-4">Analytics</h1>
        {/* Tables Area */}
        {isLoading ? (
          <Loading />
        ) :showFloodCharts?(
          <div className="flex flex-col gap-4">

            <div className="flex flex-row justify-between">
              <button onClick={() => {setShowFloodCharts(false)}} className="w-max p-2 bg-primary-green rounded-lg hover:opacity-50">
                <AiOutlineArrowLeft className="h-7 w-7 text-safe-white"/>
              </button>

              <button onClick={() => {setShowPredictionModal(true)}} className="w-max p-2 bg-primary-green rounded-lg hover:opacity-50">
                <p className="font-bold text-safe-white">Predict Data</p>
              </button>
            </div>

            <ScatterPlotChart/>

            <PredictiveAnalysisChart/>

            <RiverLevelChart/>

            <PrecipitationChart/>

            <SoilMoistureChart/>

            <Modal visible={showPredictionModal} onClose={handlePredictionModal}>
                  <>
                  {
                  !saveResult?
                  <>
                  <div className="flex flex-col">
                    <h1 className="text-2xl font-medium text-center text-primary-green">Flood Likelihood</h1>
                    <div className="mt-6 flex flex-col gap-2">
                      <div className="flex flex-col">
                        <label htmlFor="precipitation" className="relative text-safe-black">
                          Precipitation (mm)
                        </label>
                        <input
                          id="precipitation"
                          name="precipitation"
                          type="number"
                          min="0"
                          value={precipitation}
                          className="w-[100%] px-4 py-3 rounded-2xl text-sm bg-safe-gray border-2 border-secondary-gray placeholder-primary-gray focus:outline-none focus:border-primary-green focus:bg-safe-white"
                          onChange={(e) => setPrecipitation(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="soilMoisture" className="relative text-safe-black">
                          Soil Moisture (%)
                        </label>
                        <input
                          id="soilMoisture"
                          name="soilMoisture"
                          type="number"
                          min="0"
                          value={soilMoisture}
                          className="w-[100%] px-4 py-3 rounded-2xl text-sm bg-safe-gray border-2 border-secondary-gray placeholder-primary-gray focus:outline-none focus:border-primary-green focus:bg-safe-white"
                          onChange={(e) => setSoilMoisture(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col">
                        <label htmlFor="riverlevel" className="relative text-safe-black">
                          River Level (m)
                        </label>
                          <input
                            id="riverLevel"
                            name="riverLevel"
                            type="number"
                            min="0"
                            value={riverLevel}
                            className="w-full px-4 py-3 rounded-2xl text-sm bg-safe-gray border-2 border-secondary-gray placeholder-primary-gray focus:outline-none focus:border-primary-green focus:bg-safe-white"
                            onChange={(e) => setRiverLevel(e.target.value)}
                          />
                      </div>
                      <p>Result: {predictionResult}</p>
                    </div>
                  </div>
                  <div className="flex gap-4 justify-center pb-2">
                    <button onClick={handlePredictionModal} className="border-2 border-primary-green mt-6 px-10 py-2 rounded-full font-bold text-xl text-primary-green shadow-lg transition hover:bg-secondary-green hover:text-safe-white">
                      Close
                    </button>
                    <button onClick={handlePredictionSubmit} className="bg-primary-green mt-6 px-10 py-2 rounded-full font-bold text-xl text-safe-white shadow-lg transition hover:bg-secondary-green">
                      Calculate
                    </button>
                  </div>
                  </>
                  :
                  <>
                    <h1 className="text-2xl font-medium text-center pb-4">Save Result?</h1>
                    <DateSelector onDateChange={handleDateChange} hideFilter={true} defaultFilter={"yearMonthDay"} />
                    <div className="overflow-auto flex flex-col mt-4">
                    <table className="min-w-full divide-y divide-secondary-green border-2">
                      <thead className="bg-primary-green">
                        <tr>
                          <th scope="col" className="px-6 py-3 text-left text-base font-bold text-safe-white tracking-wider text-[14px] whitespace-nowrap">
                            Precipitation (mm)
                          </th>
                          <th scope="col" className="text-center px-6 py-3 text-left text-base font-bold text-safe-white tracking-wider text-[14px] whitespace-nowrap">
                            Soil Moisture (%)
                          </th>
                          <th scope="col" className="text-center px-6 py-3 text-left text-base font-bold text-safe-white tracking-wider text-[14px] whitespace-nowrap">
                            River Level (m)
                          </th>
                        </tr>
                      </thead>
                      <tbody className="bg-safe-white divide-y divide-primary-gray">
                            <tr>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div>
                                    <div className="text-sm font-medium text-secondary-green">{precipitation}</div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div>
                                    <div className="text-sm font-medium text-secondary-green">{soilMoisture}</div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div>
                                    <div className="text-sm font-medium text-secondary-green">{riverLevel}</div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                      </tbody>
                    </table>
                    </div>
                    
                    <div className="flex flex-row gap-2 mt-4">
                      {
                        !editPredictionResult? 
                        <p className="text-[20px]">Flood Likelihood: {predictionResult}</p>
                        :
                        <div className="flex flex-row gap-1">
                          <p className="text-[20px]">Flood Likelihood:</p>
                          <input 
                            type="number"
                            value={predictionResult}
                            className="w-[80px] border-2 rounded-lg text-center"
                            onChange={(e) => setPredictionResult(e.target.value)}
                          />
                        </div>
                      }
                      
                      {
                        !editPredictionResult?
                        <button onClick={() => {setEditPredictionResult(true)}}>
                        <HiOutlinePencilAlt className="h-4 w-4 mx-auto" />
                        </button>
                        :
                        <button onClick={() => {setEditPredictionResult(false)}} className="text-primary-green transition hover:opacity-70" >
                        <BsFillCheckSquareFill className="h-6 w-6 mx-auto" />                     
                        </button>

                      }

                    </div>
                    
                    <div className="flex gap-4 justify-center pb-2">
                      <button onClick={() => {setSaveResult(false)}} className="border-2 border-primary-green mt-6 px-10 py-2 rounded-full font-bold text-xl text-primary-green shadow-lg transition hover:bg-secondary-green hover:text-safe-white">
                        Back
                      </button>
                      <button onClick={handleSavePredictionData} className="bg-primary-green mt-6 px-10 py-2 rounded-full font-bold text-xl text-safe-white shadow-lg transition hover:bg-secondary-green">
                        Save
                      </button>
                    </div>
                  </>
                  }
                  </>
                </Modal>

          </div>

        ) : (
          <div className="flex flex-col w-11/12 xl:w-10/12 2xl:w-5/6 mx-auto mt-8">
            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="flex flex-col gap-4 py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">

              <Chart
                chartType="PieChart"
                data={pieData}
                options={pieOptions}
                width={"100%"}
                height={"400px"}
              />

              <div className="flex flex-row justify-between">
                <DateSelector onDateChange={handleDateChange} />

                <button onClick={() => {setShowFloodCharts(true)}} className="w-max p-2 bg-primary-green rounded-lg hover:opacity-50">
                  <BsFileEarmarkBarGraphFill className="h-7 w-7 text-safe-white"/>
                </button>
              </div>

                <div className="shadow overflow-hidden border-b border-primary-gray sm:rounded-lg">

                  <table className="min-w-full divide-y divide-secondary-green">
                    <thead className="bg-primary-green">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-left text-base font-bold text-safe-white uppercase tracking-wider">
                          Disaster
                        </th>
                        <th scope="col" className="text-center px-6 py-3 text-left text-base font-bold text-safe-white uppercase tracking-wider">
                          Number of occurence
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-safe-white divide-y divide-primary-gray">
                      {/* Filter and map report items */}
                      {data.slice(pagesVisited, pagesVisited + reportPerPage).map((report) => {
                        const { totalCount, disasterType,} = report;
                        return (
                          <tr>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="flex items-center">
                                <div>
                                  <div className="text-sm font-medium text-secondary-green">{disasterType}</div>
                                </div>
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              <div className="text-sm text-center text-safe-black">
                                <TruncateText length={30}>{totalCount}</TruncateText>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            {/* Pagination */}
            {data.length > 6 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel="Next >"
                previousLabel="< Back"
                onPageChange={handlePageChange}
                pageRangeDisplayed={3}
                pageCount={pageCount}
                // renderOnZeroPageCount={null}
                containerClassName="w-fit mt-4 py-3 mx-auto p-2 rounded-lg flex gap-4 bg-safe-white shadow"
                pageLinkClassName="outline outline-secondary-gray rounded-md px-2 py-1 text-primary-gray transition hover:outline-primary-gray active:outline-secondary-green"
                activeLinkClassName="outline-primary-green hover:outline-primary-green"
                nextClassName="text-sm my-auto text-primary-gray transition hover:text-primary-green active:text-secondary-green"
                previousClassName="text-sm my-auto text-primary-gray transition hover:text-primary-green active:text-secondary-green"
                breakClassName="text-primary-gray"
              />
            ) : null}
          </div>
        )}
      </div>
      <div className="bg-safe-gray w-full py-2 mt-8 flex flex-row md:hidden">
        <ul className="flex flex-row-reverse mx-auto gap-4">
          <li>
            <Link to="/">
              <img src={DoraHomeIcon} alt="DORAv4 Home Button" className="w-16 h-16" />
            </Link>
          </li>
          <li className="mx-2">
            <Link to="/evacuation" className="text-secondary-gray transition hover:text-primary-green focus:text-secondary-green">
              {/* <img src="src\assets\reports_icon.svg" alt="Reports Icon" className="mx-auto" /> */}
              <HiOfficeBuilding className="h-16 w-16 mx-auto" />
            </Link>
          </li>
          <li className="mx-2">
            <Link to="/reports" className="text-primary-green transition active:text-secondary-green">
              {/* <img src="src\assets\evacuation_center_icon.svg" alt="Evacuation Center Icon" className="mx-auto" /> */}
              <HiFolder className="h-16 w-16 mx-auto " />
            </Link>
          </li>
          <li className="mx-2">
            <Link to="/map" className="text-secondary-gray transition hover:text-primary-green focus:text-secondary-green">
              {/* <img src="src\assets\reports_icon.svg" alt="Reports Icon" className="mx-auto" /> */}
              <FaMapMarked className="h-16 w-16 mx-auto" />
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Analytics;