import * as tf from '@tensorflow/tfjs';
import jsonData from '../data/flood_risk_mock_data.json';
import { db } from '../configs/firebase';
import { ref, get } from 'firebase/database';


function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

// 1. Data Preparation
const convertDataToTensor = (data) => {
    const inputs = [];
    const labels = [];
    data.forEach((item) => {
        inputs.push([item.precipitation, item.soilMoisture, item.riverLevel]);
        labels.push(item.floodlikelihood);
    });
    const inputTensor = tf.tensor2d(inputs);
    const labelTensor = tf.tensor2d(labels, [labels.length, 1]);
    return { inputTensor, labelTensor };
};

// shuffleArray(jsonData);
const { inputTensor, labelTensor } = convertDataToTensor(jsonData);

// 2. Model Creation
const model = tf.sequential();

model.add(tf.layers.dense({ inputShape: [3], units: 128, activation: 'relu' }));
model.add(tf.layers.dense({ units: 64, activation: 'relu' }));
model.add(tf.layers.dense({ units: 1, activation: 'linear' }));

model.compile({
    loss: 'meanSquaredError',
    optimizer: 'adam'
});

// 3. Train the Model
async function trainModel(data) {
    const { inputTensor, labelTensor } = convertDataToTensor(data);
    const history = await model.fit(inputTensor, labelTensor, {
        epochs: 100,
        validationSplit: 0.2
    });
}


// 4. Predict
// const predictFloodLikelihood = (precipitation, soilMoisture, riverLevel) => {
//     const inputData = tf.tensor2d([[precipitation, soilMoisture, riverLevel]]);
//     const prediction = model.predict(inputData);
//     return prediction.dataSync()[0];
// };
const predictFloodLikelihood = (precipitation, soilMoisture, riverLevel) => {
    const inputData = tf.tensor2d([[precipitation, soilMoisture, riverLevel]]);
    const prediction = model.predict(inputData);
    const rawPrediction = prediction.dataSync()[0];
    // Clamping the prediction value between 0 and 5
    const clampedPrediction = Math.min(5, Math.max(0, rawPrediction));
    return clampedPrediction;
};

// Fetch data from Firebase and train the model
const fetchDataAndTrainModel = () => {
    const analyticsRef = ref(db, 'FloodAnalytics');
    
    get(analyticsRef).then((snapshot) => {
        if (snapshot.exists() && snapshot.val() != null) {
            const dbData = Object.values(snapshot.val());
            // Ensure data is properly formatted
            const formattedData = dbData.map(item => ({
                precipitation: parseFloat(item.precipitation),
                soilMoisture: parseFloat(item.soilMoisture),
                riverLevel: parseFloat(item.riverLevel),
                floodlikelihood: parseFloat(item.floodlikelihood),
            }));
            trainModel(formattedData);
        } else {
            console.error("No data available in Firebase.");
        }
    }).catch((error) => {
        console.error("Error fetching data: ", error);
    });
};

fetchDataAndTrainModel();

// Usage example
// const likelihood = predictFloodLikelihood(3.1, 12.8, 4);
// console.log('Predicted flood likelihood:', likelihood);

export { predictFloodLikelihood };

// Future Improvements: 
// - Shuffling and batching
// - Feature scaling
// - Early stopping
// - Model saving and loading
