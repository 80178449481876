import React, { useState, useEffect } from 'react';
import { Chart } from 'react-google-charts';
import jsonData from '../data/flood_risk_mock_data.json';
import { ref, get } from 'firebase/database';
import { db } from '../configs/firebase';

// Helper function to transform the JSON data into the format for the scatter plot
const transformDataForScatterPlot = (data) => {
  // Column titles
  const transformedData = [['Soil Moisture', 'Flood Likelihood']];
  
  // Data rows
  data.forEach((item) => {

    const soilMoistureValue = parseFloat(item.soilMoisture);
    const floodLikelihoodValue = parseFloat(item.floodlikelihood);

    if (!isNaN(soilMoistureValue) && !isNaN(floodLikelihoodValue)) {
      transformedData.push([soilMoistureValue, floodLikelihoodValue]);
    } else {
      console.warn('Invalid data encountered', item);
    }
  });

  return transformedData;
};


const transformDataForPredictiveAnalysis = (data) => {
  // Column titles
  const transformedData = [['Date', 'Predicted Flood Likelihood']];
  
  // Data rows
  data.forEach((item) => {
    // Ensure the flood likelihood is parsed as a float
    const floodLikelihoodValue = parseFloat(item.floodlikelihood);
    
    if (!isNaN(floodLikelihoodValue)) {
      transformedData.push([new Date(item.date), floodLikelihoodValue]);
    } else {
      console.warn('Invalid data encountered', item);
    }
  });

  return transformedData;
};

const transformDataForRiverLevel = (data) => {
  // Column titles
  const transformedData = [['Date', 'River Level']];

  // Data rows
  data.forEach((item) => {
    // Ensure the flood likelihood is parsed as a float
    const riverLevelValue = parseFloat(item.riverLevel);
    
    if (!isNaN(riverLevelValue)) {
      transformedData.push([new Date(item.date), riverLevelValue]);
    } else {
      console.warn('Invalid data encountered', item);
    }
  });
  return transformedData;
};

const transformDataForPrecipitation = (data) => {
  // Column titles
  const transformedData = [['Date', 'Precipitation']];

  // Data rows
  data.forEach((item) => {
    // Ensure the flood likelihood is parsed as a float
    const precipitationValue = parseFloat(item.precipitation);
    
    if (!isNaN(precipitationValue)) {
      transformedData.push([new Date(item.date), precipitationValue]);
    } else {
      console.warn('Invalid data encountered', item);
    }
  });
  return transformedData;
};

const transformDataForSoilMoisture = (data) => {
  // Column titles
  const transformedData = [['Time', 'Soil Moisture']];

  // Data rows
  data.forEach((item) => {
    // Ensure the flood likelihood is parsed as a float
    const soilMoistureValue = parseFloat(item.soilMoisture);
    
    if (!isNaN(soilMoistureValue)) {
      transformedData.push([new Date(item.date), soilMoistureValue]);
    } else {
      console.warn('Invalid data encountered', item);
    }
  });
  return transformedData;
};


// const transformDataForComprehensiveAnalysis = (jsonData) => {
//   const data = [['Date', 'Predicted Flood Likelihood', 'River Level', 'Precipitation']];
//   jsonData.forEach((item) => {
//     data.push([
//       new Date(item.Date), 
//       item.FloodLikelihood,
//       item.RiverLevel, 
//       item.Precipitation
//     ]);
//   });
//   return data;
// };

const convertDatesToISO = (data) => {
  return data.map(item => {
    // Assuming 'date' is the property that contains the date string
    const dateWithTime = `${item.date}T00:00:00.000Z`;
    return {
      ...item, // Spread the other properties of the object
      date: dateWithTime // Overwrite the 'date' property with the new string
    };
  });
};

const ScatterPlotChart = () => {
  const [scatterPlotData, setScatterPlotData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const analyticsRef = ref(db, 'FloodAnalytics');
    
    get(analyticsRef).then((snapshot) => {
      if (snapshot.exists() && snapshot.val() != null) {

        const dbData = Object.values(snapshot.val());

        const formattedData = dbData.map(item => ({
          ...item,
          date: new Date(item.date).toISOString(),
          floodlikelihood: parseFloat(item.floodlikelihood),
          precipitation: parseFloat(item.precipitation),
          riverLevel: parseFloat(item.riverLevel),
          soilMoisture: parseFloat(item.soilMoisture),
        }));
        setScatterPlotData(transformDataForScatterPlot(formattedData));
      } else {
        setScatterPlotData(transformDataForScatterPlot(jsonData));
      }
      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching data: ", error);
      setLoading(false);
    });
  }, []);

  const options = {
    title: 'Scatter Plot of Soil Moisture vs. Flood Likelihood',
    hAxis: { title: 'Soil Moisture (%)' },
    vAxis: { title: 'Flood Likelihood' },
    legend: 'none',
    trendlines: { 0: {} }, // Draw a trendline for data series 0.
  };
  return scatterPlotData.length > 1 ? (
    <Chart
      chartType="ScatterChart"
      width="100%"
      height="400px"
      data={scatterPlotData}
      options={options}
    />
  ) : (
    <div>Loading...</div>
  );
};

const PredictiveAnalysisChart = () => {
  const [predictiveData, setPredictiveData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const analyticsRef = ref(db, 'FloodAnalytics');
    
    get(analyticsRef).then((snapshot) => {
      if (snapshot.exists() && snapshot.val() != null) {
        const dbData = Object.values(snapshot.val());

        // Format the data with the correct types
        const formattedData = dbData.map(item => ({
          ...item,
          date: new Date(item.date).toISOString(), // Make sure the date is in the correct format
          floodlikelihood: parseFloat(item.floodlikelihood) // Parse the flood likelihood as a float
        }));
        setPredictiveData(transformDataForPredictiveAnalysis(formattedData));
      } else {
        setPredictiveData(transformDataForPredictiveAnalysis(jsonData));
      }
      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching data: ", error);
      setLoading(false);
    });
  }, []);

  const options = {
    title: 'Predictive Analysis of Flood Likelihood Over Time',
    hAxis: { title: 'Date', format: 'MMM dd, yyyy' }, // You can customize the date format as needed
    vAxis: { title: 'Predicted Flood Likelihood' },
    legend: 'none',
  };

  return !loading ? (
    <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={predictiveData}
      options={options}
    />
  ) : (
    <div>Loading...</div>
  );
};

const RiverLevelChart = () => {
  const [riverLevelData, setRiverLevelData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const analyticsRef = ref(db, 'FloodAnalytics');
    
    get(analyticsRef).then((snapshot) => {
      if (snapshot.exists() && snapshot.val() != null) {
        const dbData = Object.values(snapshot.val());

        // Format the data with the correct types
        const formattedData = dbData.map(item => ({
          ...item,
          date: new Date(item.date).toISOString(),
          riverLevel: parseFloat(item.riverLevel)
        }));
        setRiverLevelData(transformDataForRiverLevel(formattedData));
      } else {
        setRiverLevelData(transformDataForRiverLevel(jsonData));
      }
      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching data: ", error);
      setLoading(false);
    });
  }, []);

  const options = {
    title: 'River Level Over Time',
    hAxis: { title: 'Date' },
    vAxis: { title: 'River Level' },
    legend: 'none',
  };
  
  return riverLevelData.length > 1 ? (
    <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={riverLevelData}
      options={options}
    />
  ) : (
    <div>Loading...</div>
  );
};

const PrecipitationChart = () => {
  const [precipitationData, setPrecipitationData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const analyticsRef = ref(db, 'FloodAnalytics');
    
    get(analyticsRef).then((snapshot) => {
      if (snapshot.exists() && snapshot.val() != null) {
        const dbData = Object.values(snapshot.val());

        // Format the data with the correct types
        const formattedData = dbData.map(item => ({
          ...item,
          date: new Date(item.date).toISOString(),
          precipitation: parseFloat(item.precipitation)
        }));
        setPrecipitationData(transformDataForPrecipitation(formattedData));
      } else {
        setPrecipitationData(transformDataForPrecipitation(jsonData));
      }
      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching data: ", error);
      setLoading(false);
    });
  }, []);

  const options = {
    title: 'Precipitation Over Time',
    hAxis: { title: 'Date' },
    vAxis: { title: 'Precipitation' },
    legend: 'none',
  };
  
  return precipitationData.length > 1 ? (
    <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={precipitationData}
      options={options}
    />
  ) : (
    <div>Loading...</div>
  );
};

const SoilMoistureChart = () => {
  const [soilMoistureData, setSoilMoistureData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const analyticsRef = ref(db, 'FloodAnalytics');
    
    get(analyticsRef).then((snapshot) => {
      if (snapshot.exists() && snapshot.val() != null) {
        const dbData = Object.values(snapshot.val());

        // Format the data with the correct types
        const formattedData = dbData.map(item => ({
          ...item,
          date: new Date(item.date).toISOString(),
          soilMoisture: parseFloat(item.soilMoisture)
        }));
        setSoilMoistureData(transformDataForSoilMoisture(formattedData));
      } else {
        setSoilMoistureData(transformDataForSoilMoisture(jsonData));
      }
      setLoading(false);
    }).catch((error) => {
      console.error("Error fetching data: ", error);
      setLoading(false);
    });
  }, []);

  const options = {
    title: 'Soil Moisture Over Time',
    hAxis: { title: 'Date' },
    vAxis: { title: 'Soil Moisture (%)' },
    legend: 'none',
    trendlines: { 0: {} }, // Draw a trendline for data series 0 if desired
  };

  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="400px"
      data={soilMoistureData}
      options={options}
    />
  );
};

// const ComprehensiveAnalysisChart = () => {
//   const [comprehensiveData, setComprehensiveData] = useState(transformDataForComprehensiveAnalysis(jsonData));

//   const options = {
//     title: 'Comprehensive Analysis of Flood Risk Factors Over Time',
//     hAxis: { title: 'Date' },
//     vAxis: { title: 'Value' },
//     series: {
//       0: { targetAxisIndex: 0 },  // Predicted Flood Likelihood uses left axis
//       1: { targetAxisIndex: 1 },  // River Level uses right axis
//       2: { targetAxisIndex: 1 }   // Precipitation uses right axis
//     },
//     vAxes: {
//       0: { title: 'Flood Likelihood' },
//       1: { title: 'River Level & Precipitation' }
//     }
//   };

//   return comprehensiveData.length > 1 ? (
//     <Chart
//       chartType="LineChart"
//       width="100%"
//       height="400px"
//       data={comprehensiveData}
//       options={options}
//     />
//   ) : (
//     <div>Loading...</div>
//   );
// };


export { ScatterPlotChart, PredictiveAnalysisChart, RiverLevelChart, PrecipitationChart, SoilMoistureChart };
