import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { ref, set } from 'firebase/database';
import { db } from '../configs/firebase';
import { toast } from "react-toastify";

import DoraLogo from "../assets/dora_logo6.png";

const Register = () => {

	const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        const auth = getAuth();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const newUserUid = userCredential.user.uid;

            await set(ref(db, 'Admins/' + newUserUid), {
                isAdmin: true
            });

            toast.success("Admin user created successfully");
            navigate("/")
        } catch (error) {
            console.error("Error Found: ", error);
            if (error.code === 'auth/email-already-in-use') {
                toast.error('User already exists with this email');
            } else {
                toast.error('Error creating user: ' + error.message);
            }
        }
    };

    return (
    	<div className="flex flex-col h-screen justify-center items-center bg-bg-color">
      	{/* Logo */}
	      	<div className="bg-safe-white p-4 rounded-2xl mb-8">
	        	<img src={DoraLogo} alt="DORAv4 Logo" className="max-h-[200px] max-w-[200px]" />
	      	</div>
	      	{/* Register */}
	      	<div className="bg-safe-white p-6 px-10 pb-10 rounded-2xl">
		        <form onSubmit={handleSubmit}>
		        	<div className="flex flex-col gap-4 items-center">
		            <input 
		            type="email" 
		            className="w-full px-10 py-3 rounded-2xl text-sm bg-safe-gray border-2 border-secondary-gray placeholder-primary-gray focus:outline-none focus:border-primary-green focus:bg-safe-white" 
		            value={email} 
		            onChange={e => setEmail(e.target.value)} 
		            placeholder="Email" 
		            required />
		            
		            <input 
		            type="password" 
		            className="w-full px-10 py-3 rounded-2xl text-sm bg-safe-gray border-2 border-secondary-gray placeholder-primary-gray focus:outline-none focus:border-primary-green focus:bg-safe-white"
		            value={password} 
		            onChange={e => setPassword(e.target.value)} 
		            placeholder="Password" 
		            required />
		            
		            <button type="submit" className="mt-4 w-full bg-primary-green py-3 rounded-full font-bold text-xl text-safe-white shadow-lg transition hover:bg-secondary-green">Register Admin</button>
		            <button onClick={() => {navigate("/")}} className="w-max font-bold text-xl text-primary-green transition hover:opacity-70">Cancel</button>
		            </div>
		        </form>
	        </div>
        </div>
    );
};

export default Register;
