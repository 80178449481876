export default function NotificationNotch({isVisible}){

	if(isVisible) return null

	return (

		<div className="absolute top-2 right-1 transform translate-x-1/2 -translate-y-1/2
                    flex justify-center items-center 
                    w-4 h-4 text-xs font-bold text-[#ff0505] bg-[#ff0505]  border-2 rounded-full">
	      1
	    </div>

	);
};